
import React from 'react';

interface Props {
  // Define Props interface here
}

const Products: React.FC<Props> = () => {
  return (
    <div className='text-center max-w-screen-lg flex flex-col'>
      <div className='rounded-xl'>
        <h2 className='text-center text-4xl text-zinc-100 mt-4'>Hvem er vi?</h2>
        <p className='text-center max-w-128 my-4 mx-16 text-zinc-200'>
          Sjappemaker er en liten norsk bedrift basert på Innlandet. Vi er en liten gjeng ingeniører som ønsker å anvende vår ekspertise fra IT-bransjen til å hjelpe bedrifter- og enkeltpersoner å lykkes på nett.
        </p>
      </div>

      <div className='rounded-xl'>
        <h2 className='text-center text-4xl text-zinc-100 mt-4'> Hvorfor velge oss?</h2>
        <p className='text-center max-w-128 my-4 mx-16 text-zinc-300'>Det er ekstremt mange som ønsker å lykkes på nett i dag og det kan være vanskelig å skille fageksperter fra hobbyutviklere som kun ønsker å tjene penger.
          Sjappemaker tilbyr kun erfarne utviklere med <span className='text-blue-400'> over 10 års bransjeerfaring</span> hos anerkjente selskaper. Alle våre utviklere har <span className='text-blue-400'>master- eller ingeniørutdanning innen IT</span>, og kan garantere ekspertise og pålitelighet. Enten du er enkeltperson eller bedrift, kan du være trygg på at du investerer i kvalitet når du velger oss.
        </p>
      </div>

      <div className='rounded-xl'>
        <h2 className='text-center text-4xl text-zinc-100 mt-4'> Hvordan funker det?</h2>
        <div className='text-center flex flex-col max-w-128 my-4 mx-16 text-zinc-400'>
          <p>1. Send oss en beskrivelse av ditt behov.</p>
          <p>2. Vi kontakter deg innen 24 timer og avtaler et møte.</p>
          <p>3. Vi tar et møte hvor vi diskuterer en løsning basert på dine behov.</p>
          <p>4. Vi sender deg et uforpliktende tilbud, hvor du også får en oversikt over aktuell sjappemaker (utvikler) sin bakgrunn og erfaring, slik at du kan ta et informert valg.</p>
        </div>
      </div>
    </div>
  );
};

export default Products;
