import React from 'react';
import { Card } from '../../shared/Card';
import styles from './Products.module.scss'
interface Props {
  // Define your component props here
}

const Products: React.FC<Props> = () => {
  return (
    <div>
      <h1 className='text-center my-8 text-4xl text-zinc-300'> Populære tjenester </h1>
      <p className='text-center my-8 text-zinc-400'> Dette er eksempler på våre mest populære tjenester - vi tilpasser hvert oppdrag og pris spesifikt til ditt behov.</p>
      <div className='flex flex-col md:flex-row gap-4'>
        <Card>
          <article className="relative w-full h-full p-4 md:p-8">
            <div>
              <div className="flex gap-2 text-xs text-zinc-100">
                <div
                  className={styles.shopify}
                ></div>
                <h2
                  className="text-2xl font-bold text-zinc-100 group-hover:text-white sm:text-2xl"
                >
                  Nettsjappe
                </h2>
              </div>
            </div>
            <div className="text-xs mt-2 font-calSans text-zinc-400">
              Privatperson, liten eller stor bedrift?
            </div>
            <ul className="mt-4 mx-4 font-calSans text-blue-400 space-y-2 text-xs list-disc">
              <li>
                <span className='text-zinc-300'>Utforming av en unik, moderne nettbutikk</span>
              </li>
              <li>
                <span className='text-zinc-300'>SEO (søkemotoroptimalisering) for økt salg </span>
              </li>
              <li>
                <span className='text-zinc-300'>Enkel administrering + opplæring</span>
              </li>
              <li>
                <span className='text-zinc-300'>Integrasjon mot betalingsplatformer</span>
              </li>
              <li>
                <span className='text-zinc-300'>Bruks og kjøpsstatistikk- samt live-statistikk</span>
              </li>
              <li>
                <span className='text-zinc-300'>Hold oversikt i mobilapp</span>
              </li>
            </ul>
            <div className='pt-4 flex flex-row justify-between'>
              <p className="hidden text-zinc-400 font-calSans hover:text-zinc-50 lg:block">
                Les mer <span aria-hidden="true">&rarr;</span>
              </p>
              <p className='flex text-zinc-400 items-center text-xs font-calSans'
              >Fra 8990,- kr*
              </p>
            </div>
          </article>
        </Card>

        <Card>
          <article className="relative w-full h-full p-4 md:p-8">
            <div>
              <div className="flex gap-2 text-xs text-zinc-100">
                <div className='text-lg font-semibold'>
                  &lt;<span className='text-blue-400'> / </span>&gt;
                </div>
                <h2
                  className="text-2xl font-bold text-zinc-100 group-hover:text-white sm:text-2xl"
                >
                  Webapplikasjon
                </h2>
              </div>
            </div>
            <div className="text-xs mt-2 font-calSans text-zinc-400">
              Applikasjon på nett, tilpasset dine behov
            </div>
            <ul className="mt-4 mx-4 font-calSans text-blue-400 space-y-2 text-xs list-disc">
              <li>
                <span className='text-zinc-300'>Planlegging og utvikling av ny applikasjon</span>
              </li>
              <li>
                <span className='text-zinc-300'>Videreutvikling av eksisterende applikasjon</span>
              </li>
              <li>
                <span className='text-zinc-300'>Utarbeiding av teknisk arkitektur</span>
              </li>
              {/* <li>
                            <span className='text-zinc-300'>Du kan legge føringer på teknologi, om du ønsker. React, Angular, Java, .NET, HTML5, CSS3, Tailwind, Bootstrap, Google Cloud, Azure, AWS, Firebase, osv </span>
                            </li> */}
              <li>
                <span className='text-zinc-300'>Integrasjoner mot tredjeparts-programvare</span>
              </li>
              <li>
                <span className='text-zinc-300'>Content Management</span>
              </li>
              <li>
                <span className='text-zinc-300'>..og mye mer. Vi løser ditt behov.</span>
              </li>
            </ul>
            <div className='pt-4 flex flex-row justify-between'>
              <p className="hidden text-zinc-400 font-calSans hover:text-zinc-50 lg:block">
                Les mer <span aria-hidden="true">&rarr;</span>
              </p>
              <p className='flex items-center text-zinc-400 text-xs font-calSans'
              >Pris etter avtale
              </p>
            </div>
          </article>
        </Card>
        <Card>
          <article className="relative w-full h-full p-4 md:p-8">
            <div>
              <div className="flex gap-2 text-xs text-zinc-100">
                <div className='text-lg font-semibold'>
                  👨‍💻
                </div>
                <h2
                  className="text-2xl font-bold text-zinc-100 group-hover:text-white sm:text-2xl"
                >
                  Lei en utvikler
                </h2>
              </div>
            </div>
            <div className="text-xs mt-2 font-calSans text-zinc-400">
              Lei en utvikler til å jobbe med ditt prosjekt
            </div>
            <ul className="mt-4 mx-4 font-calSans text-blue-400 space-y-2 text-xs list-disc">
              <li>
                <span className='text-zinc-300'>Endre- eller videreutvikle eksisterende applikasjon</span>
              </li>
              <li>
                <span className='text-zinc-300'>Kvalitetssikring av kode- og arkitektur</span>
              </li>
              <li>
                <span className='text-zinc-300'>Risikovurdering av system</span>
              </li>
              {/* <li>
                            <span className='text-zinc-300'>Du kan legge føringer på teknologi, om du ønsker. React, Angular, Java, .NET, HTML5, CSS3, Tailwind, Bootstrap, Google Cloud, Azure, AWS, Firebase, osv </span>
                            </li> */}
              <li>
                <span className='text-zinc-300'>Implementering av testrammeverk</span>
              </li>
              <li>
                <span className='text-zinc-300'>Risikohåndtering</span>
              </li>
              <li>
                <span className='text-zinc-300'>..og mye mer. Vi løser ditt behov.</span>
              </li>
            </ul>
            <div className='pt-4 flex flex-row justify-between'>
              <p className="hidden text-zinc-400 font-calSans hover:text-zinc-50 lg:block">
                Les mer <span aria-hidden="true">&rarr;</span>
              </p>
              <p className='flex items-center text-zinc-400 text-xs font-calSans'
              >Fra 1300 kr/t
              </p>
            </div>
          </article>
        </Card>
      </div>
    </div>
  );
};

export default Products;