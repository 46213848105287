

import { useRef } from 'react';
import styles from "./App.module.scss";
import MySection from './components/MySection';
import Particles from './components/visual/Particles';
import Products from './components/sections/products/Products';
import About from './components/sections/about/About';
import Contact from './components/sections/contact/Contact';

const navigation = [
  { name: "Tjenester", href: "/contact" },
  { name: "Om oss", href: "/contact" },
  { name: "Kontakt", href: "/contact" },
];


export default function App() {
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef<HTMLElement | null>(null);
  const section4 = useRef<HTMLElement | null>(null);

  function scrollTo(section: React.RefObject<HTMLElement>) {
    section.current?.scrollIntoView({ behavior: "smooth" });
  }

  function navigationClicked(index: number) {
    if (index === 0) {
      scrollTo(section2);
    } else if (index === 1) {
      scrollTo(section3);
    }
    else if (index === 2) {
      scrollTo(section4);
    }

  }

  return (
    <div className='bg-zinc-950'>
      <Particles
        className="absolute inset-0 animate-fade-in"
        quantity={80}
      />

      <div className="flex flex-col items-center justify-center w-screen h-screen bg-gradient-to-tl from-black via-zinc-600/20 to-black z-5">
        <div className={`cont ${styles.container}`}>
          <div ref={section1}>
            <MySection
              goToSectionRef={section2}
              scrollTo={scrollTo}
              showArrow={true}
            >
              <nav className="my-8 animate-fade-in z-50">
                <ul className="flex items-center justify-center gap-4">
                  {navigation.map((item, index) => (
                    <label className="fake-link group transition duration-300 font-medium z-50" onClick={() => navigationClicked(index)}>{item.name}
                      <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
                    </label>
                  ))}
                </ul>
              </nav>
              <div className="hidden w-screen h-px animate-glow md:block animate-fade-left bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
              <div className="flex flex-col items-center justify-center">
                <h1 className="z-5 text-5xl text-transparent duration-1000 bg-gradient-to-r from-zinc-400 via-white to-zinc-400 cursor-default text-edge-outline animate-title font-calSans sm:text-6xl md:text-9xl whitespace-nowrap bg-clip-text py-6">
                  sjappemaker<span className='text-blue-400'>.</span>no
                </h1>

              </div>
              <div className="hidden w-screen h-px animate-glow md:block animate-fade-right bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
              <div className="my-16 text-center animate-fade-in">
                <h2 className="text-sm text-zinc-500">
                  Vi bygger din <span className='text-blue-400 font-medium'>nettbutikk</span> og gir deg alt du trenger for å lykkes med netthandel.

                </h2>
                <h2 className="text-sm text-zinc-500">
                  Alle våre utviklere har over <span className='text-blue-400 font-medium'>10 års profesjonell erfaring</span> med webutvikling.
                </h2>
              </div>
            </MySection>
          </div>
          <div ref={section2}>
            <MySection
              goToSectionRef={section3}
              scrollTo={scrollTo}
              showArrow={true}
            >
              <Products>

              </Products>
            </MySection>

          </div>
          <div ref={section3 as React.RefObject<HTMLDivElement>}>
            <MySection
              goToSectionRef={section4 as React.RefObject<HTMLElement>}
              scrollTo={scrollTo}
              showArrow={true}
            >
              <About>

              </About>

            </MySection>

          </div>

          <div ref={section4 as React.RefObject<HTMLDivElement>}>
            <MySection
              goToSectionRef={section1 as React.RefObject<HTMLElement>}
              scrollTo={scrollTo}
              showArrow={false}
            >
              <Contact>

              </Contact>

            </MySection>
          </div>

        </div>

      </div>

      {/* <div className="flex flex-col items-center justify-center w-screen h-screen overflow-hidden bg-gradient-to-tl from-black via-zinc-600/20 to-black"> */}

      {/* <nav className="my-16 animate-fade-in z-50">
        <ul className="flex items-center justify-center gap-4">
          {navigation.map((item) => (
            <a className="group transition duration-300 font-medium" href="www.vg.no">{item.name} 
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span>
            </a>
          ))}
        </ul>
      </nav>
    <div className="hidden w-screen h-px animate-glow md:block animate-fade-left bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
    <Particles
        className="absolute inset-0 -z-0 animate-fade-in"
        quantity={500}
      />
    <h1 className="z-10 py-6 text-5xl text-transparent duration-1000 bg-gradient-to-r from-zinc-400 via-white to-zinc-400 cursor-default text-edge-outline animate-title font-calSans sm:text-6xl md:text-9xl whitespace-nowrap bg-clip-text">
    sjappemaker<span className='text-blue-400'>.</span>no
    </h1>
    <div className="hidden w-screen h-px animate-glow md:block animate-fade-right bg-gradient-to-r from-zinc-300/0 via-zinc-300/50 to-zinc-300/0" />
    <div className="my-16 mx-16 text-center animate-fade-in">
      <h2 className="text-sm text-zinc-500 ">
       Vi bygger din <span className='text-blue-400 font-medium'>nettbutikk</span> og gir deg alt du trenger for å lykkes med netthandel.

      </h2>
    </div> */}
    </div>
  )
}
