import { useRef, useState } from "react";
import { Card } from "../../shared/Card";
import emailjs from '@emailjs/browser';
import { CopperLoading } from "respinner";

interface Props {
  // Define Props interface here
}

const Contact: React.FC<Props> = () => {
  const maxLength = 500;
  const [sendingEmail, setSendingEmail] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    service: '',
    description: '',
    isBusiness: false,
  });

  const hasSubmittedForm = useRef(false);

  const sendEmail = (e: any, formData: any) => {
    e.preventDefault();

    setSendingEmail(true);
    emailjs
      .sendForm('service_hfcu66r', 'template_d2by03j', e.target, {
        publicKey: 'REVVAID-zkaME5JgM',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSendingEmail(false);
        },
        (error) => {
          console.log(error);
          console.log('FAILED...', error.text);
          setSendingEmail(false);
        },
      );
  };

  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (event: { target: { name: any; checked: any; }; }) => {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if ((e.target as HTMLFormElement).reportValidity()) {
      console.log(e);
      console.log('Send email med følgende form', formData);
      sendEmail(e, formData);
      hasSubmittedForm.current = true;
    } else {
      console.log('Kontaktskjema inneholder feil.');
    }
  };

  return (
    <div>
      <Card>
        <section className="min-w-96 p-12 space-y-6 md:py-6 lg:p-10">
          {sendingEmail ?
            <>
              <div className="container flex flex-col  items-center justify-center">
                <CopperLoading fill="#777" />
              </div>

            </>
            :

            hasSubmittedForm.current ? <div className="text-center font-calSans text-zinc-200"><h2 className="text-3xl mb-8">
              Takk for din henvendelse! </h2> Vi vil ta kontakt med deg innen 24 timer.</div>
              :
              <form
                onSubmit={handleSubmit}
                id='contact-form'
              >
                <div className="container flex flex-col space-y-4 px-4 md:px-6 items-center justify-center">

                  <div className="space-y-2 text-center">
                    <h2 className="text-3xl text-zinc-300 font-bold">Send oss en melding</h2>
                    <p className="text-zinc-300 dark:text-gray-400">

                      Fyll ut dine opplysninger og vi vil ta kontakt med deg innen 24 timer.
                    </p>
                  </div>
                  <div className="flex flex-col space-y-4 min-h-0 w-full max-w-sm">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex flex-col space-y-2">
                        <label
                          className="font-calSans text-sm text-zinc-300 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          Navn
                        </label>
                        <input
                          className="flex h-10 w-full rounded-md border border-input bg-transparent text-zinc-100 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                          id="name"
                          placeholder="Ditt navn"
                          type="text"
                          name="name"
                          maxLength={20}
                          value={formData.name}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label
                          className="font-calSans text-sm text-zinc-300 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                        >
                          E-mail
                        </label>


                        <input
                          className="flex h-10 w-full rounded-md border border-input bg-transparent text-zinc-100 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                          type="email"
                          id="email"
                          placeholder="Din e-postadresse"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="flex flex-col space-y-2">
                      <label
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        <select
                          name="service"
                          value={formData.service}
                          onChange={handleInputChange}
                          required
                          className="flex h-10 w-full rounded-md border border-input bg-transparent text-zinc-100 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"

                        >
                          <option value="">Ditt behov</option>
                          <option value="Nettbutikk">🛒 Nettbutikk</option>
                          <option value="Webutvikling">🌐 Webutvikling</option>
                          <option value="Lei en utvikler">👨‍💻 Lei en utvikler</option>
                          <option value="Annet">❔Annet</option>
                        </select>
                      </label>
                    </div>
                    <label className="font-calSans text-zinc-100">
                      <input
                        type="checkbox"
                        name="isBusiness"
                        checked={formData.isBusiness}
                        onChange={handleCheckboxChange}
                      /> Jeg henvender meg på vegne av en bedrift
                    </label>
                    <div>
                      <label
                        className="font-calSans text-sm text-zinc-300 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Beskrivelse (max 500)
                        <textarea
                          style={{ resize: 'none' }}
                          name="description"
                          maxLength={maxLength}
                          value={formData.description}
                          onChange={handleInputChange}
                          required
                          className="flex h-40 w-full rounded-md border border-input bg-transparent text-zinc-200 px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"

                        />
                      </label>
                    </div>
                    <div>
                      <button className={
                        `flex rounded-md border ring-offset-background justify-center h-8 align-center font-calSans w-full leading-7 text-zinc-200 border-blue-400}`
                      }
                        type="submit">Send</button>
                    </div>
                  </div>
                </div>
              </form>
          }
        </section>

      </Card>
    </div >
  )
}

export default Contact;