import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRef, useEffect } from "react";
import styles from "./MySection.module.scss";

gsap.registerPlugin(ScrollTrigger);

export default function MySection({
  scrollTo,
  goToSectionRef,
  showArrow,
  children
}: {
  scrollTo: (goToSectionRef: any) => void;
  goToSectionRef: React.RefObject<HTMLElement>;
  showArrow: boolean;
  children: any;
}) {
  const sectionRef = useRef(null);
  const headlineRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      headlineRef.current,
      {
        autoAlpha: 0,
        y: -20,
      },
      {
        y: 0,
        autoAlpha: 1,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          scroller: ".cont",
          trigger: headlineRef.current,
          start: "top 60%",
          end: "bottom 0%",
          toggleActions: "play none restart reverse",
        },
      }
    );
    return () => { };
  }, []);
  return (
    <div className={styles.section} ref={sectionRef}>
      <div>
        <div>{children}</div>
      </div>
      {showArrow && (
        <button
          className={styles.downarrow}
          onClick={() => scrollTo(goToSectionRef)}
        ></button>
      )}
    </div>
  );
}